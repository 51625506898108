// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-testing-example-1-js": () => import("./../../../src/pages/testing/example1.js" /* webpackChunkName: "component---src-pages-testing-example-1-js" */),
  "component---src-pages-testing-example-2-js": () => import("./../../../src/pages/testing/example2.js" /* webpackChunkName: "component---src-pages-testing-example-2-js" */),
  "component---src-pages-testing-example-3-js": () => import("./../../../src/pages/testing/example3.js" /* webpackChunkName: "component---src-pages-testing-example-3-js" */),
  "component---src-pages-testing-example-4-js": () => import("./../../../src/pages/testing/example4.js" /* webpackChunkName: "component---src-pages-testing-example-4-js" */),
  "component---src-pages-testing-menu-js": () => import("./../../../src/pages/testing/menu.js" /* webpackChunkName: "component---src-pages-testing-menu-js" */)
}

